import React, { Component } from 'react'

class BasicContent extends Component {
  render() {
    return (
      <div className="basic-content">
        <div dangerouslySetInnerHTML={{__html: this.props.content }} />
      </div>
    )
  }
}

export default BasicContent
