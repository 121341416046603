import React, { Component } from 'react'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import he from 'he'

import ContactForm from '../components/contact-form'
import BasicContent from '../components/basic-content'
import TextItem from '../components/archive/text-item'
import SocialBar from '../components/social-bar'
import Instagram from '../components/instafeed'

const social = {
  title: 'Add me to the Dad list',
  className: 'full'
}


class ContactPage extends Component {
  render() {
    const contact = this.props.data.wordpressPage
    const posts = this.props.data.allWordpressPost
    return (
      <>
        <Helmet>
          <body />
          <title>{ he.decode(this.props.data.wordpressPage.yoast_meta.yoast_wpseo_title) }</title>
          <meta name="description" content={this.props.data.wordpressPage.yoast_meta.yoast_wpseo_metadesc} />
        </Helmet>
        <ContactForm {...contact} />
        <SocialBar {...social} />
        <Instagram />
        <BasicContent {...contact} />
        <div className='archive__text-item__container'>
          <div className='archive__text-item__inner'>
            { posts.edges && posts.edges.map((el, i) => {
              return <TextItem {...el.node} key={i} />
            }) }
          </div>
        </div>
      </>
    )
  }
}

export default ContactPage

export const contactQuery = graphql`
  query contactQuery {
    wordpressPage(title: {eq: "Contact"}) {
      content
      yoast_meta {
        yoast_wpseo_title
        yoast_wpseo_metadesc
      }
      acf {
        title
        form_title
        featured_image {
          url
          alt
        }
      }
    }
    allWordpressPost(limit: 8) {
      edges {
        node {
          id
          title
          slug
          acf {
            featured_image {
              url
              alt
            }
          }
        }
      }
    }
  }
`
