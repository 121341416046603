import React, {Component} from 'react'

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}

class ContactForm extends Component {

  constructor(props) {
    super(props);
    this.state = {
      name: '',
      email: '',
      enquiry: '',
      emailValidation: '',
      nameValidation: ''
    };
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleSubmit = e => {
    e.preventDefault();
    const form = e.target;
    if ( this.state.name === '' || this.state.email === '' ) {
      if ( this.state.name === '' ) {
        this.setState({result: 'fail', nameValidation: 'Please enter your name.' })
      } else {
        this.setState({result: 'fail', nameValidation: '' })
      }
      if ( this.state.email === '' ) {
        this.setState({result: 'fail', emailValidation: 'Please enter an email address.' })
      } else {
        this.setState({result: 'fail', emailValidation: '' })
      }
    } else {
      fetch("/contact?no-cache=1", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: encode({
          "form-name": form.getAttribute("name"),
          ...this.state
        })
      })
        .then(() => this.setState({result: 'success', msg: 'Thanks for contacting us. One of the team will be in touch shortly.', emailValdiation:'', nameValdiation:''}))
        .catch(error => this.setState({result: 'fail', msg: error}));
    }
  }

  render() {

    let backgroundImage = this.props.acf.featured_image.url

    return(
      <section className="contact-form" style={{ backgroundImage: `url(${backgroundImage})`}}>
        <div className="contact-form__inner">
          <div className="contact-form__content">
            <p className="contact-form__title">{this.props.acf.title}</p>
          </div>
          <div className="contact-form__form">
            <h1>{this.props.acf.form_title}</h1>
            { this.state.result !== 'success' &&
              <form
                className="form"
                name="contact"
                method="post"
                data-netlify="true"
                data-netlify-honeypot="bot-field"
                onSubmit={this.handleSubmit}
              >
                <input type="hidden" name="form-name" value="contact" />
                <input type="hidden" name="bot-field" />
                <div className="form__row">
                  <input type="text" name="name" id="name" placeholder="Name" onChange={this.handleChange} />
                  { this.state.nameValidation !== '' &&
                    <p className="validation" dangerouslySetInnerHTML={{ __html: this.state.nameValidation }} />
                  }
                </div>
                <div className="form__row">
                  <input type="email" name="email" id="email" placeholder="Email Address" onChange={this.handleChange} />
                  { this.state.emailValidation !== '' &&
                    <p className="validation" dangerouslySetInnerHTML={{ __html: this.state.emailValidation }} />
                  }
                </div>
                <div className="form__row">
                  <input type="text" name="number" id="number" placeholder="Contact Number" onChange={this.handleChange} />
                </div>
                <div className="form__row form__row--select">
                  <select name="help" id="help" onChange={this.handleChange}>
                    <option value="">Type of Contact</option>
                    <option value="consulting">General</option>
                    <option value="development">Submissions</option>
                  </select>
                  <svg width="8px" height="5px" viewBox="0 0 8 5" version="1.1">
                    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                      <g transform="translate(-274.000000, -12.000000)" fill="#231F20">
                        <path d="M274.2416,12.2603966 C274.566517,11.9243621 275.100017,11.9243621 275.424933,12.2603966 L277.958433,14.8725517 L280.491517,12.2520345 C280.816433,11.916 281.349933,11.916 281.674767,12.2520345 C281.999683,12.5881552 281.999683,13.1400517 281.674767,13.4760862 L278.550183,16.7088448 C278.391933,16.8724655 278.1836,16.9586724 277.95835,16.9586724 C277.733433,16.9586724 277.5251,16.8637586 277.366517,16.7088448 L274.241517,13.4845345 C274.08335,13.3121207 274.000017,13.0881552 274.000017,12.8726379 C274.0001,12.6483276 274.083433,12.4328103 274.2416,12.2603966 Z" id="Fill-1"></path>
                      </g>
                    </g>
                  </svg>
                </div>
                <div className="form__row">
                  <textarea name="enquiry" id="enquiry" placeholder="Enquiry" onChange={this.handleChange} />
                </div>
                <div className="form__row form__row--submit">
                  <input className="btn" type="submit" value="Contact" />
                </div>
              </form>
            }
            { this.state.result === 'success' &&
              <div className="contact-form__content contact-form__content--confirmation">
                <p dangerouslySetInnerHTML={{ __html: this.state.msg }} />
              </div>
            }
          </div>
        </div>
      </section>
    )

  }

}

export default ContactForm
